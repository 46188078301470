//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-520:_4604,_4636,_9688,_6528,_3588,_5756,_4552,_8476;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-520')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-520', "_4604,_4636,_9688,_6528,_3588,_5756,_4552,_8476");
        }
      }catch (ex) {
        console.error(ex);
      }